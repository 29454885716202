import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Router} from '@reach/router';
import {withPrefix} from 'gatsby';
import Layout from '../components/Layout/Layout';

import Section from '../components/Layout/Section/Section';
import Meta, {appendSeoTitleSuffix} from '../components/Layout/Meta';
import {PageTemplateStyled} from '../templates/styles';
import TherapyVerificator from '../components/Container/TherapyInformation/TherapyVerificator/TherapyVerificator';

const DeepLink = ({location}) => {
	const [pzn, setPzn] = useState('');
	const [ar, setAr] = useState(false);
	const [lang, setLang] = useState(undefined);
	const [chapter, setChapter] = useState(undefined);

	const AuthToken = ({token}) => {
		let newToken = token;

		if (token.includes('-AR')) {
			const _newToken = token.split('-');
			newToken = _newToken[0];
			setAr(true);
		} else if (token.charAt(2) === '-') {
			const _newToken = token.split('-');
			setLang(_newToken[0].toLowerCase());
			newToken = _newToken[1];
		} else if (token.includes('-')) {
			const _newToken = token.split('-');
			newToken = _newToken.splice(0, 1)[0];

			setChapter(_newToken.join('-'));
		}
		setPzn(newToken);
		return null;
	};

	return (
		<>
			<Meta title={appendSeoTitleSuffix('Therapieinformationen')} />
			<Layout location={location}>
				<PageTemplateStyled>
					<Section showPadding>
						<Router>
							<AuthToken path={withPrefix('/therapieinformationen/:token')} />
						</Router>
						<div style={{display: 'none'}}>
							<TherapyVerificator value={pzn} ar={ar} lang={lang} chapter={chapter} />
						</div>
						<h1>Weiterleitung</h1>
					</Section>
				</PageTemplateStyled>
			</Layout>
		</>
	);
};

DeepLink.propTypes = {
	location: PropTypes.shape({search: PropTypes.string}).isRequired,
};

export default DeepLink;
